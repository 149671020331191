import React, { PureComponent } from "react";
import SearchInput from './SearchInput'
import CategoriesResults from "./CategoriesResults.js"
import RDVResults from "./RDVResults.js"
import BrandResults from "./BrandResults.js"
import PhoneResults from "./PhoneResults";
import PhoneData from "./PhoneData.js";
import RecapPage from "./RecapPage.js";
import FinalPage from './FinalPage.js'
import APIUtilities from './APIUtilities.js'
import Footer from './Footer.js';
import Header from './Header.js';
import uuid from 'react-uuid';

import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";

import { Steps, Space } from 'antd';

import "./App.css";

const { Step } = Steps;

const steps = [
    { title: 'Catégorie', description: 'Catégorie' },
    { title: 'Marque', description: 'Marque' },
    { title: 'Modèle', description: 'Modèle' },
    { title: 'Réparations', description: 'Réparations' },
    { title: 'Récapitulatif', description: 'Récapitulatif' },
    { title: 'Tarifs', description: 'Tarifs' },
  ];

const CATEGORY_CHOICE = 1;
const BRAND_CHOICE = 2;
const MODEL_CHOICE = 3;
const PHONE_DATA = 4;
const RECAP = 5;
const RDV_PAGE = 11 ;
const FINAL_PAGE = 10;


const TABLET = 1;
const TEL = 2
const PC = 3;
const OBJECT = 4;

const NONE = -1;

class App extends PureComponent {

  constructor(props) {
    super(props);
    //CATEGORY_CHOICE
    this.state = {
      currentState:CATEGORY_CHOICE,
      currentModel: {"IDModele" : NONE},
      currentSearch: NONE+"",
      GUID: uuid(),
      showPrices: false,
      campaign : "",
      activeStep : 0,
      IDSite : undefined
    };

    //this.handleClickOnAriane = this.handleClickOnAriane.bind(this);
    this.handleClickOnCategory = this.handleClickOnCategory.bind(this);
    this.handleClickOnBrand = this.handleClickOnBrand.bind(this);
    this.handleClickOnPhone = this.handleClickOnPhone.bind(this);
    this.handleClickOnValidateItems = this.handleClickOnValidateItems.bind(this);
    this.handleClickOnSubmitDevis = this.handleClickOnSubmitDevis.bind(this);
    this.clickReturnButton = this.clickReturnButton.bind(this);
    this.handleClickOnSubmitRDVPage = this.handleClickOnSubmitRDVPage.bind(this);

    
  };

  /*
  handleClickOnAriane(event) {
    alert("Ariane");
  }*/

  componentDidMount() {
    const { match, history } = this.props;
    const campaign = APIUtilities.getCampaignId();
    this.setState({ campaign: campaign });
    history.push("/?campagne=" + campaign);
  }

  handleClickOnCategory(category) {
    var nextState = BRAND_CHOICE;

    window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    const id = parseInt(category.IDCategorie);

    this.setState({
      currentCategory: category,
      currentBrand: {"id" : NONE},
      currentModel: {"IDModele" : NONE},
      selectedItems: [],
      discount: 0,
      total: 0,
      currentState: nextState,
      activeStep: nextState -1
    });
  };

  handleClickOnBrand(id, name) {
    var nextState = MODEL_CHOICE;
    var activeStepValue = nextState -1;


    if(id === 0) /* Clique sur marque "Autre" */
      nextState = RECAP;
      activeStepValue = nextState -1;

      this.setState({
        currentState: nextState,
        currentBrand: {"id" : id, "name" : name},
        currentModel: {"IDModele" : NONE},
        selectedItems: [],
        discount: 0,
        total: 0,
        activeStep: activeStepValue,
      }, () => window.scrollTo({
              top: 0,
              behavior: "smooth",
          }));
  };

  handleClickOnPhone(selectedPhone) {
    var nextState = PHONE_DATA;
    var activeStepValue = nextState -1;


    if(parseInt(selectedPhone.IDModele) === 0) /* Clique sur modele "Autre" */
      nextState = RECAP;
      activeStepValue = nextState -1;

    this.setState({
      currentState: nextState,
      currentModel: selectedPhone,
      selectedItems: [],
      discount: 0,
      total: 0,
      activeStep: activeStepValue,
    }, () => window.scrollTo({
            top: 0,
            behavior: "smooth",
        }) );


  };

  clickReturnButton() {
    const currentState = this.state.currentState;
    const currentCategoryID = parseInt(this.state.currentCategory.IDCategorie);
    var nextState = currentState-1;

    if (this.state.currentState === BRAND_CHOICE) {
      window.location.reload();
    }

    window.scrollTo({
            top: 0,
            behavior: "smooth",
        });


    if(currentState === RECAP) {

      if(this.state.currentBrand.id === 0)
        nextState = BRAND_CHOICE;

      else if(parseInt(this.state.currentModel.IDModele) === 0)
        nextState = MODEL_CHOICE;
    }
    this.setState({
      currentState : nextState,
      currentSearch: NONE+"",
      activeStep: nextState -1,
    });
  };

  handleClickOnValidateItems(selectedItems, discount, total) {
    if(selectedItems.length === 0) {
      alert("Veuillez selectionner au moins 1 réparation afin de continuer");
      return;
    }

    window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    APIUtilities.sendPreDevis(this.state.GUID, selectedItems, this.state.campaign);

    this.setState({
      currentState: RECAP,
      selectedItems: selectedItems,
      discount: discount,
      total: total,
      activeStep: this.state.currentState,
    });
  };

  handleClickOnSubmitDevis(discountCodeAmount, pe_IDSite) {

    window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    this.setState({
      currentState: FINAL_PAGE,
      discount: this.state.discount+discountCodeAmount,
      total: this.state.total-discountCodeAmount,
      activeStep: this.state.currentState,
      IDSite : pe_IDSite
    });
  }


  handleClickOnSubmitRDVPage() {

    window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    this.setState({
      currentState: RDV_PAGE
    });
  }







  /*Gestion du changement de text dans la barre de recherche*/
  handleSearchChange = event => {
    var val = event.target.value;

    if(val === "")
      val = NONE+"";

    this.setState({currentSearch: val});
  };

  stepper() {

  if (window.innerWidth <= 750) {
  return (
      <div className="steps">
        <Steps size="small" current={this.state.activeStep}>
          {steps.map((step, index) => (
            <Step key={index}/>
          ))}
        </Steps>
      </div>
  );
} else {
  return (
    <div className="steps">
      <Steps size="small" current={this.state.activeStep}>
        {steps.map((step, index) => (
          <Step key={index}  title={step.title}/>
        ))}
      </Steps>
    </div>
  );
}
}

  addReturnButton() {
    if(this.state.currentState > CATEGORY_CHOICE && this.state.currentState < FINAL_PAGE)
      return (
        <Router>
        <Link to={`/?campagne=${this.state.campaign}`}>
        <button className="return-button" onClick={this.clickReturnButton}>Retour</button>
        </Link>
        </Router>
      );
  }

  addSearchInput() {
    if(this.state.currentState === MODEL_CHOICE)
      return <SearchInput category = {this.state.currentCategory} textChange={this.handleSearchChange} brandName={this.state.currentBrand.name}/>;
  }

  getCurrentContent() {

    switch(this.state.currentState) {
    case CATEGORY_CHOICE:
      return <CategoriesResults onClickFunction={this.handleClickOnCategory}/>;

    case BRAND_CHOICE:
      return <BrandResults category = {this.state.currentCategory} onClickFunction={this.handleClickOnBrand} campaign={this.state.campaign}/>;

    case MODEL_CHOICE:
      return <PhoneResults category = {this.state.currentCategory} brandID={this.state.currentBrand.id} search={this.state.currentSearch} onClickFunction={this.handleClickOnPhone} campaign={this.state.campaign}/>;

    case PHONE_DATA:
      return <PhoneData phoneData={this.state.currentModel} onClickFunction={this.handleClickOnValidateItems} showPrices={this.state.showPrices} campaign={this.state.campaign}/>;

    case RECAP:
      return <RecapPage category={this.state.currentCategory} brand={this.state.currentBrand} model={this.state.currentModel}
        selectedItems={this.state.selectedItems} discount={this.state.discount} total={this.state.total} GUID={this.state.GUID}
        onClickFunction={this.handleClickOnSubmitDevis} showPrices={this.state.showPrices} IDSite={this.state.IDSite} campaign={this.state.campaign}/>;

    case FINAL_PAGE:
      return (<FinalPage GUID={this.state.GUID} category={this.state.currentCategory} brand={this.state.currentBrand} model={this.state.currentModel}
        selectedItems={this.state.selectedItems} discount={this.state.discount} total={this.state.total} campaign={this.state.campaign}
        onClickFunction={this.handleClickOnSubmitRDVPage} 
        />);

    case RDV_PAGE:
      return <RDVResults  IDSite={this.state.IDSite}  GUID={this.state.GUID} onClickFunction={this.handleClickOnCategory} campaign={this.state.campaign}/>;

    default:
      console.log("default");
      return "";
    }
  }

  render() {
    return (
      <div className="main-content">
        <div className="page_wrapper">
          <Header />
          <div className="main-page">
            {/*
            Fonctionnalités supplémentaires V2
            <Ariane onClickFunction={this.handleClickOnAriane}/>*/}
            {this.stepper()}
            {this.addReturnButton()}
            {this.addSearchInput()}
            {this.getCurrentContent()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
