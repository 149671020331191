import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import APIUtilities from './APIUtilities.js'
import SkeletonCard from "./SkeletonCard";
import logoQualiRepar from './assets/logoQualiRepar.png';
import logoQualiReparDesktop from './assets/logoQualiReparDesktop.png';



export default class CategoriesResults extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      firstLoad : true,
      selectedCategory: null
    };
  };

  static propTypes = {
    onClickFunction: PropTypes.func
  };

  async getCategoryList() {
    const list = await APIUtilities.generateCategoryList();
    this.setState({
      firstLoad : false,
      categoryList : list
    });
  }

  componentDidMount() {
    this.getCategoryList();
  }

  selectCategory(category) {
    this.props.onClickFunction(category);
  };

  render() {
    if(this.state.firstLoad)
      return <SkeletonCard nbItems="4"/>;
    else {
      return (
        <>

        
          <div className="qualireparDesktop">
            <img width="100%"  alt="qualirepar" src={logoQualiReparDesktop} />
          </div>

        <div className="results-title">
        <div className="qualireparMobile">
            <img width="100%"  alt="qualirepar" src={logoQualiRepar} />
          </div>
        Sélectionner la catégorie
        </div>
        <div className="component-grid-list">
          {this.state.categoryList.map(category => (
            <div className="component-grid-element component-results-element" id="component-categories-results-element" key={category.IDCategorie} onClick={this.selectCategory.bind(this, category)}>
              <img alt ={category.LibelleCategorie} src={category.URL_IMG} />
              <span className="component-grid-element-title">{category.LibelleCategorie}</span>
            </div>
          ))}
        </div>
        </>
      );
    }
  }
}
