import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import DataForm from './DataForm';
import APIUtilities from './APIUtilities.js'
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";

import "./RecapPage.css";
import proctection_LIST from "./_ProtectionList.json";

const styles = theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 2,
        borderColor: '#D9D9D9',
      },
      '&:hover fieldset': {
        borderColor: '#205685',
      },
    }
  }
});

class RecapPage extends PureComponent {
  static propTypes = {
    category: PropTypes.object,
    brand: PropTypes.object,
    model: PropTypes.object,
    selectedItems: PropTypes.array,
    discount: PropTypes.number,
    total: PropTypes.number,
    GUID: PropTypes.string,
    onClickFunction: PropTypes.func
  };

  constructor (props) {
    super(props);
    this.state = {
      discountHelperText: '',
      discountError: false,
      discountCode: '',
      discountCodeAmount: 0,
      GUID: props.GUID
    };
    this.sendDevis = this.sendDevis.bind(this);
    this.validatePromoCode = this.validatePromoCode.bind(this);
  }

  componentDidMount() {
  const { campaign } = this.props;
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("campagne", campaign);
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState(null, null, newUrl);
  }

  addRecapItems() {
    return(
      <div className="component-recap-bloc">
        <div className="component-recap-bloc-image">
            {this.printImg()}
        </div>
        <div className="component-recap-bloc-model">
          <div className="component-recap-bloc-model-title">
            {this.printModel()}
          </div>
          <div>
            {this.printSelectedItems()}
          </div>
          <br/>
          <div style={{fontSize: "14px", fontWeight: 600,}}>Le prix total sera affiché sur la prochaine page. ➡️ </div>
        </div>
        {/*
        <div className="component-recap-bloc-prices">
          Total
          <div className="component-recap-bloc-totalAmount">
            {this.printTotal()}
          </div>
          <div>
            {this.printDiscount()}
          </div>
        </div>
        */}
      </div>
    );
  }

  //div protection
  printItemProtection(item) {
    return(
      <label className="lab">
      <div className="item-protection-recapPage">
      <div>
        <img src={item.PictoPath_Piece} className="picto-protection-recapPage"/>
      </div>
      <div className="box-protection">
        <div className="info-box-label-proctection-recapPage">
          {item.Piece}
        </div>
      </div>
      </div>
      </label>);
  }

  printImg() {
    const idPhone = parseInt(this.props.model.IDModele);
    if(idPhone === -1)
      return <img alt ={this.props.category.LibelleCategorie} src={this.props.category.URL_IMG} />;

    return <img alt ={this.props.model.LibelleModele} src={this.props.model.URL_IMG} />;
  }

  printModel() {
    const idCat = parseInt(this.props.category.IDCategorie);
    const idBrand = parseInt(this.props.brand.id);
    const idPhone = parseInt(this.props.model.IDModele);

    //Catégories autres que "Téléphone" ou (catgéorie "Téléphone" et marque "Autre")
    if(idCat !== 2 || idBrand === 0)
      return(this.props.category.LibelleCategorie);

    //Catégorie "Téléphone" et modèle "autre"
    if(idPhone < 1)
      return(this.props.category.LibelleCategorie + " " + this.props.brand.name);

    return(this.props.model.LibelleMarque + " "+this.props.model.LibelleModele);
  }

  printSelectedItems() {
    const items = this.props.selectedItems;

    // Exclure l'élément avec l'ID 2493
    const filteredItems = items.filter(item => item.IDArticle !== 2493);

    if (filteredItems.length === 0)
      return "Aucune réparation sélectionnée";

    const labels = filteredItems.map((item, index) => (
      <div key={index} style={{ margin: '10px 0' }}>{item.Piece}</div>
    ));

    return (
      <div>
        {/*Réparation :*/}
        <div>{labels}</div>
      </div>
    );
  }


//affichage de la div protection si selectionner
  printProtection(item) {
    const items = this.props.selectedItems;

    if (items.find(item => item.IDArticle === 2493)) {
      return (
        <div className="component-phone-data-item-list">

          {proctection_LIST.map(item => (
            <div key={item.IDArticle} className="component-phone-data-item-element">
              {this.printItemProtection(item)}
            </div>
          ))}

        </div>
      );
    }
    return null;
  }

  printTotal() {
    if(this.props.total === 0)
      return "Sur devis";
    if(this.props.showPrices === false)
      return "Page suivante..."
    return (this.props.total-this.state.discountCodeAmount)+"€";
  }

  printDiscount() {
    return "("+(this.props.discount+this.state.discountCodeAmount) +"€ de réduction)";
  }

  addPromoCode() {
    const {classes} = this.props;
    return(
      <form
        className={classes.root+" component-promo-code"}
        noValidate
        autoComplete="off"
        onSubmit={this.validatePromoCode}>
        <div>
        <div className="input-group">
          <TextField
          style={{
            width: '100%',}}
          className = "discountField"
            id="discountCode"
            label="Code de réduction"
            error={this.state.discountError}
            helperText={this.state.discountHelperText}
            variant="outlined"/>
            </div>
          </div>
          <div>
          <button className="validate-button" id="validate-promo-code-button" variant="contained" label="Submit" type="submit">Vérifier le code</button>
        </div>
      </form>
    );
  }

  async validatePromoCode(event) {
    event.preventDefault();
    const code = event.target.discountCode.value;
    var discountCode = '';
    var discountHelperText = 'Ce code est invalide';
    var discountError = true;
    const discountCodeAmount = await APIUtilities.validatePromoCode(code);

    if(discountCodeAmount > 0) {
      discountHelperText = "Ce code vous fait bénéficier de "+discountCodeAmount+"€ de réduction."
      discountError = false;
      discountCode = code;
    }

    this.setState({
      discountCode : discountCode,
      discountCodeAmount : discountCodeAmount,
      discountHelperText: discountHelperText,
      discountError: discountError
    });
  };

  async sendDevis(event, site) {
    event.preventDefault();
    var details = "";

    this.state.IDSite = site;

    if(this.props.selectedItems.length === 0)
      details = event.target.details.value;

    await APIUtilities.sendDevis(event.target.name.value,
                                 event.target.email.value,
                                 event.target.phone.value.replace(/ /g, ""),
                                 site,
                                 this.state.GUID,
                                 this.props.selectedItems,
                                 details,
                                 this.state.discountCode,
                                 this.props.campaign);

    this.props.onClickFunction(this.state.discountCodeAmount, site);
  }

  render() {
    return (
      <>
      <div className="component-recap-page">
        <h1 className="component-recap-page-title">Récapitulatif de ma demande</h1>

        <p className="component-recap-page-info">Bénéficiez de 10% de remise immédiate en remplissant vos coordonnées ci-dessous, un conseiller vous contactera dans les plus brefs délais pour prendre rendez-vous avec vous.</p>
        <p className="component-recap-page-info">Ce devis est valable en boutique Phoner pour une durée de 1 mois !</p>
        {this.addRecapItems()}
        {this.printProtection()}
        {/*<div className="component-recap-add-info">Le prix total sera affiché dans la prochaine page.</div>*/}
        {this.addPromoCode()}
        <Router>
        <DataForm onSubmit={this.sendDevis} details={this.props.selectedItems.length === 0 ? 1 : 0}/>
        </Router>
        {/*<div className="sub-info">
        Un de nos techniciens prendra contact avec vous pour définir une date de rendez vous en boutique sous 24h
        </div>*/}
      </div>
      </>
    );
  }
}

export default withStyles(styles)(RecapPage);
