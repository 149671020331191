import React, { PureComponent } from "react";
import Calendar from './Calendar.js'
import "./FinalPage.css";
import proctection_LIST from "./_ProtectionList.json";
import APIUtilities from './APIUtilities.js'
import { Button, Modal } from 'antd';

export default class FinalPage extends PureComponent {

  componentDidMount() {

    const { campaign } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("campagne", campaign);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, null, newUrl);

  }

  addRecapItems() {

    return(
      <div className="component-recap-bloc">
        <div className="component-recap-bloc-image">
            {this.printImg()}
        </div>
        <div className="component-recap-bloc-model">
          <div className="component-recap-bloc-model-title">
            {this.printModel()}
          </div>
          <div>
            {this.printSelectedItems()}
          </div>
        </div>
      </div>
    );
  }

  //div protection
  printItemProtection(item) {
    return(
      <label className="lab">
      <div className="item-protection-recapPage">
      <div>
        <img src={item.PictoPath_Piece} className="picto-protection-recapPage"/>
      </div>
      <div className="box-protection">
        <div className="info-box-label-proctection-recapPage">
          {item.Piece}
        </div>
      </div>
      </div>
      </label>);
  }

  printImg() {
    const idPhone = parseInt(this.props.model.IDModele);
    if(idPhone === -1)
      return <img alt ={this.props.category.LibelleCategorie} src={this.props.category.URL_IMG} />;

    return <img alt ={this.props.model.LibelleModele} src={this.props.model.URL_IMG} />;
  }

  printModel() {
    const idCat = parseInt(this.props.category.IDCategorie);
    const idBrand = parseInt(this.props.brand.id);
    const idPhone = parseInt(this.props.model.IDModele);

    //Catégories autres que "Téléphone" ou (catgéorie "Téléphone" et marque "Autre")
    if(idCat !== 2 || idBrand === 0)
      return(this.props.category.LibelleCategorie);

    //Catégorie "Téléphone" et modèle "autre"
    if(idPhone < 1)
      return(this.props.category.LibelleCategorie + " " + this.props.brand.name);

    return(this.props.model.LibelleMarque + " "+this.props.model.LibelleModele);
  }

  printSelectedItems() {
    const items = this.props.selectedItems;

    // Exclure l'élément avec l'ID 2493
    const filteredItems = items.filter(item => item.IDArticle !== 2493);

    if (filteredItems.length === 0)
      return (<div style={{fontWeight: '100' }}>Aucune réparation sélectionnée</div>);

    const labels = filteredItems.map((item, index) => (
      <div key={index} style={{margin: '10px 0' }}>{item.Piece}</div>
    ));

    return (
      <div>
        Réparation :
        <div style={{fontWeight: '100' }}>{labels}</div>
      </div>
    );
  }

  //affichage de la div protection si selectionner
  printProtection(item) {
      const items = this.props.selectedItems;

      if (items.find(item => item.IDArticle === 2493)) {
        return (
          <div className="component-phone-data-item-list">

            {proctection_LIST.map(item => (
              <div key={item.IDArticle} className="component-phone-data-item-element">
                {this.printItemProtection(item)}
              </div>
            ))}

          </div>
        );
      }
      return null;
  }

  printTotal() {
    if(this.props.total === 0)
      return "Sur devis";
    return this.props.total.toFixed(2)+"€";
  }

  printTotalBonusReparation() {
    if(this.props.total === 0)
      return "Sur devis";
    return (this.props.total - 25).toFixed(2)+"€";
  }

  printDiscount() {
    return this.props.discount != 0 ? "("+this.props.discount +"€ de réduction)" : "";
  }

  state = {
    showModal: false,
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }));
  };

  showModal = () => {

    this.props.onClickFunction();

     // Affichage de la page de rendez-vous.

      /*
        APIUtilities.sendDemandeRDVDevis(this.props.GUID);
        this.setState({
          showModal: true,
        });

    */

};

hideModal = () => {
  this.setState({
    showModal: false,
  });
};

handleOk = () => {
  this.hideModal();
};

  render() {
    return (
      <>
      <div className="end-message">
        <h4>Récapitulatif de ma demande</h4>
        <div className="component-recap-FinalPage">
          {this.addRecapItems()}
        </div>
        {this.printProtection()}

        <div className="printDiscount">
          {this.printDiscount()}
        </div>

        <div className="component-recap-bloc-prices">
          Total (TTC)
          <div className="component-recap-bloc-totalAmount">
            {this.printTotal()}
          </div>
        </div>

        <div className="component-recap-bloc-prices">
        <div className="printDiscountQUALIREPAR">
            <p>-25€ si éligible au bonus réparation(1)</p>
        </div>
          <div className="component-recap-bloc-totalAmount">
            <div className="printDiscountQUALIREPAR_Total">
              <p>{this.printTotalBonusReparation()}</p>
            </div>
          </div>
        </div>

        <div className="end-message_rdv">
        <button type="button" onClick={this.showModal}>Prendre rendez-vous et bénéficier de -10%</button>
        </div>

        <div className="sub-info">
        Le récapitulatif de votre demande a été envoyé sur l’email indiqué précédemment, pensez à vérifier vos spams !
        </div>
        </div>
      <div className="box-info">
     
      <div className=" end-message end-message-button">
       

      <Modal
        title={<h2 className="modal-title">Merci !</h2>}
        visible={this.state.showModal}
        onCancel={this.hideModal}
        footer={[
          <Button key="fermer" onClick={this.handleOk}>
            Fermer
          </Button>,
        ]}
      >
        <p className="modal-text">
          Votre prise de rendez-vous a bien été prise en compte, notre équipe reviendra vers vous au plus vite.
        </p>
      </Modal>

      </div>
      <a href="https://www.phoner.co/acheter-son-smartphone-neuf-reconditionne">
      <div className="end-info">
      Découvrir nos smartphones reconditionnés &#x1F1EB;&#x1F1F7;
      </div>
      <div className=" end-message end-message-button">
          <a href="/"><button>Générer un nouveau devis</button></a>
      </div>
      <div className="sub-info">
      Un de nos techniciens va prendre contact avec vous <strong>par téléphone et par mail</strong> pour définir une date de rendez-vous en boutique sous 24h
      </div>
      </a>

      <div className="detailBonusReprise">
        (1) Le bonus réparation Qualirepar n’est valable que sur le territoire Français sous condition d’acceptation par les éco-organisme selon l’éligibilité du produit et de la panne. L’éligibilité est testée et vous sera confirmée en point de vente par nos techniciens au moment du rendez-vous réparation. Voir conditions sur label-qualirepar.fr
      </div>

      </div>
      </>);
  }
}
