import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import APIUtilities from './APIUtilities.js'
import SkeletonCard from "./SkeletonCard";
import { Button , Modal, Result} from 'antd';

import { CaretLeftOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';


const formatter = new Intl.DateTimeFormat("fr-FR", {
  weekday: "long",
  day: "numeric",
  month: "long"
});

const SELECT_RDV_CHOICE   = 0;
const VALID_RDV_CHOICE    = 1;
const FINAL_PAGE_RDV      = 2;

export default class RDVResults extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {

      firstLoad : true,
      numberWeek : 0,
      year : 0,
      creneau_LIST : [],
      horaire_LIST : [],
      dateCurrent : undefined,
      dateDay1 : undefined,
      stepRDV : SELECT_RDV_CHOICE,
      selectedCreneau : undefined,
      showModal : false

    };
  };

  static propTypes = {
    onClickFunction: PropTypes.func
  };


  componentDidMount() {

    let dateCurrent = new Date();
    let dateDay1  = new Date();
    dateCurrent   = new Date(dateCurrent.setDate(dateCurrent.getDate() + 2));
    dateDay1      = new Date(dateDay1.setDate(dateDay1.getDate() + 2));

    var result = this.getWeekNumber(dateCurrent);
    this.state.dateCurrent = dateCurrent;
    this.setState({dateDay1, dateCurrent,  year : result[0], numberWeek : result[1]})
    
   // console.log('getWeekNumber');
   // console.log(result);
   // console.log(this.getMondayAndSaturday(result[1], result[0]));

    this.generateCreneau();
    this.getCrenauList();

  }

  generateCreneau(){
    


      let creneau_LIST = [];

      /*
      for (let index = 0; index < 6; index++) {
        console.log(index)

        let horaire_LIST = []

        horaire_LIST.push({HeureDebut : '11:00:00'}, {HeureFin : '12:00:00'})
        horaire_LIST.push({HeureDebut : '12:00:00'},  {HeureFin : '13:00:00'})
        horaire_LIST.push({HeureDebut : '14:00:00'},  {HeureFin : '15:00:00'})
        horaire_LIST.push({HeureDebut : '15:00:00'},  {HeureFin : '16:00:00'})
        horaire_LIST.push({HeureDebut : '16:00:00'},  {HeureFin : '17:00:00'})
        
        let creneau = {
          NumSemaine : semaine ,
          NumJourSemaine : index +1,
          LibelleJour : this.getLibelleJourByNumero(index +1),
          Horaire_LIST : horaire_LIST
        }
        creneau_LIST.push(creneau)

      }
      */
     // console.log(creneau_LIST)


      let horaire_LIST = [];

      // new Date(date).getDay() === 0

      let dateCreneau = new Date(this.state.dateCurrent);

      let IDSite = this.props.IDSite;

      console.log('IDSite',IDSite);

     

      if(new Date(this.state.dateCurrent).getDay() != 0){

        if(new Date(this.state.dateCurrent).getDay() == 1 && IDSite == 4){

        }else{
          horaire_LIST.push({HeureDebut : '11:00', HeureFin : '12:00', DateDebutCreneau : new Date(dateCreneau.setHours(11, 0, 0)), DateFinCreneau :  new Date(dateCreneau.setHours(12, 0, 0)) })
        horaire_LIST.push({HeureDebut : '14:00', HeureFin : '15:00', DateDebutCreneau : new Date(dateCreneau.setHours(14, 0, 0)), DateFinCreneau :  new Date(dateCreneau.setHours(15, 0, 0)) })
        horaire_LIST.push({HeureDebut : '15:00', HeureFin : '16:00', DateDebutCreneau : new Date(dateCreneau.setHours(15, 0, 0)), DateFinCreneau :  new Date(dateCreneau.setHours(16, 0, 0)) })
        horaire_LIST.push({HeureDebut : '16:00', HeureFin : '17:00', DateDebutCreneau : new Date(dateCreneau.setHours(16, 0, 0)), DateFinCreneau :  new Date(dateCreneau.setHours(17, 0, 0)) })
        horaire_LIST.push({HeureDebut : '17:00', HeureFin : '18:00', DateDebutCreneau : new Date(dateCreneau.setHours(17, 0, 0)), DateFinCreneau :  new Date(dateCreneau.setHours(18, 0, 0)) })
        console.log(horaire_LIST)
         
        }
        

      }

     

      this.setState({creneau_LIST, horaire_LIST});


  }

  getLibelleJourByNumero(pe_numeroSemaine){

    if(pe_numeroSemaine == 1){
      return "Lundi"
    }
    if(pe_numeroSemaine == 2){
      return "Mardi"
    }
    if(pe_numeroSemaine == 3){
      return "Mercredi"
    }
    if(pe_numeroSemaine == 4){
      return "Jeudi"
    }
    if(pe_numeroSemaine == 5){
      return "Vendredi"
    }
    if(pe_numeroSemaine == 6){
      return "Samedi"
    }

  }

  formaterDate = (date) => {
    return formatter.format(date);
  };

  getMondayAndSaturday(weekNumber, year) {

    const firstDayOfYear = new Date(year, 0, 1);
    const daysOffset = (weekNumber - 1) * 7; // Nombre de jours à ajouter pour atteindre la semaine demandée

    // Trouver le premier lundi de l'année
    const firstMonday = new Date(firstDayOfYear);
    firstMonday.setDate(firstDayOfYear.getDate() + (firstDayOfYear.getDay() === 0 ? 1 : (8 - firstDayOfYear.getDay())));

    // Calcul des dates du lundi et samedi de la semaine donnée
    const monday = new Date(firstMonday);
    monday.setDate(firstMonday.getDate() + daysOffset);

    const saturday = new Date(monday);
    saturday.setDate(monday.getDate() + 5);

    return {
        monday: monday.toISOString().split('T')[0], // Format YYYY-MM-DD
        saturday: saturday.toISOString().split('T')[0]
    };
  
  }

  getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return [d.getUTCFullYear(), weekNo];
  }

  async getCrenauList() {
    const list = await APIUtilities.generateCreneauList('a8bca01-f284-6736-66b0-a8fad4ae1481');
    console.log(list)

    this.setState({
      firstLoad : false,
      categoryList : list
    });
  }

  selectCategory(category) {
    this.props.onClickFunction(category);
  };

  renderDateSelecteur(){

    return this.formaterDate(this.state.dateCurrent).charAt(0).toUpperCase() + this.formaterDate(this.state.dateCurrent).slice(1);   

  }

  addDay(){

    let dateCurrent = new Date(this.state.dateCurrent);
    console.log('dateCurrent',dateCurrent);
    dateCurrent = dateCurrent.setDate(dateCurrent.getDate() + 1);
    console.log('dateCurrent',dateCurrent);
    this.state.dateCurrent = dateCurrent;
   this.setState({dateCurrent});
   this.reloadCreneau();
   
  }

  prevDay(){
    console.log('prevDay')
    let dateCurrent = new Date(this.state.dateCurrent);
    console.log('dateCurrent',dateCurrent);
    dateCurrent = dateCurrent.setDate(dateCurrent.getDate() - 1);
    this.state.dateCurrent = dateCurrent;
    this.setState({dateCurrent});
    this.reloadCreneau();
  }

  reloadCreneau(){
    var result = this.getWeekNumber(new Date(this.state.dateCurrent));
    this.generateCreneau();
  }

  selectCreneau(item){
    console.log(item);
    this.state.selectedCreneau = item;
    this.setState({selectedCreneau : item, showModal : true})

    //let dateCreneau = new Date(this.state.dateCurrent);

  }


  formatDateToSQL(dateInput) {
    const date = new Date(dateInput);

    if (isNaN(date.getTime())) {
        throw new Error("Date invalide");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



  handleOk = () => {
   // setIsModalOpen(false);

  

   let selectedCreneau =  this.state.selectedCreneau;
   selectedCreneau.DateDebutCreneau   = this.formatDateToSQL(selectedCreneau.DateDebutCreneau);
   selectedCreneau.DateFinCreneau     = this.formatDateToSQL(selectedCreneau.DateFinCreneau);



   APIUtilities.sendDemandeRDVDevisV2(this.props.GUID, this.state.selectedCreneau);

   this.handleCancel();

   this.setState({stepRDV : FINAL_PAGE_RDV }) 
  };

  handleCancel = () => {
   // setIsModalOpen(false);
   this.setState({showModal : false})
  };



  render() {

    if(this.state.firstLoad)
      return <SkeletonCard nbItems="4"/>;
    else {
      return (
        <>

     
        { this.state.stepRDV == SELECT_RDV_CHOICE &&
          <div className="results-title">
            Réservez votre créneau dès maintenant
          </div>
        }

        { this.state.stepRDV == SELECT_RDV_CHOICE &&
               <div className="wapper_rdv">

               <div className="dateSelectRDV">
                 { this.state.dateCurrent > new Date(this.state.dateDay1) &&
                   <span onClick={() => this.prevDay()} ><CaretLeftOutlined style = {{visible : 'none'}} /></span>
                 }
                 {this.renderDateSelecteur()}<span onClick={() => this.addDay()}><CaretRightOutlined /></span>
               </div>
     
                 {
                 this.state.horaire_LIST.map(category => (
                   <div>
                        <Button onClick={ () => this.selectCreneau(category) } className="button_rdv">{category.HeureDebut}</Button>
                   </div>
                   ))
                 }
               
             </div>
        }

        { this.state.showModal == true &&
        <Modal 
          title="Valider mon rendez-vous" 
          open={this.state.showModal} 
          onOk={this.handleOk} 
          onCancel={this.handleCancel}
          okText="Valider"
          cancelText="Annuler"
          cancelButtonProps={{style: {color : '#1677FF', backgroundColor: 'white'}}}
          >
          <p>{'Prendre votre rendez-vous le ' + this.formaterDate(this.state.dateCurrent) + ' à ' + this.state.selectedCreneau.HeureDebut + ' ?'}</p>
        </Modal>
        }
     


      { this.state.stepRDV == VALID_RDV_CHOICE &&
          <div className="wapper_rdv">
          <div>
            <h3>Résumé de votre rendez-vous</h3>
            <div className="dateValideRDV">
             {'Prendre rendez-vous le ' + this.formaterDate(this.state.dateCurrent) + ' à ' + this.state.selectedCreneau.HeureDebut + ' ?'}
            </div>
          </div>
          </div>
      }

      {   this.state.stepRDV == FINAL_PAGE_RDV &&
          <div className="wapper_rdv">
            
            <Result
                status="success"
                title="Votre demande de rendez-vous a bien été prise en compte"
                subTitle="Notre équipe vous rappellera rapidement afin de valider votre créneau en fonction des stocks disponibles."
                extra={[
                  <a href="/">
                    <Button type="primary" key="console">
                    Nouveau devis
                    </Button>   
                  </a>
                ]}
              />
          </div>
      }
    



   

    
        </>
      );
    }
  }
}

/*
 //  {this.formaterDate(this.state.dateCurrent)} à {this.state.selectedCreneau.HeureDebut}
*/

/*
  this.setState({showModal : false})
*/